<template>
  <div class="otherwrap">
    <div class="stuitem" v-for="(item, index) in stulist" :key="index">
      <div class="item-info">
        <div class="avatar">
          <img :src="imgurl+item.userAvatar" :onerror="imgUrl" />
        </div>
        <div class="userinfo">
          <div class="name">
            <span>{{ item.userName }}</span>
            <el-rate v-model="item.correctingContent.score" disabled v-if="item.correctingContent"></el-rate>
            <span>{{
              item.correctingContent
                ? item.correctingContent.score + ".0"
                : null
            }}
              <i v-if="item.correctingContent">分</i></span>
          </div>
          <div class="time">提交于{{ item.createTime }}</div>
        </div>
      </div>
      <div class="num-box" v-if="item.score == 'true'">
        <div>
          <span class="num">{{ item.totalPoints }}</span>
          <br />总得分
        </div>
        <div>
          <span class="num">{{ item.objectiveScore }}</span>
          <br />客观题得分
        </div>
        <div>
          <span class="num">{{ item.objectiveAccuracy }}%</span>
          <br />客观题正确率
        </div>
        <div>
          <span class="num">{{ item.subjectiveScore }}</span>
          <br />主观题得分
        </div>
        <div>
          <span class="num">
            {{item.answerDuration.split("分钟")[0]}}
            <span style="font-size:15px;font-weight: normal">分</span>
            {{item.answerDuration.split("分钟")[1].split("秒")[0]}}
            <span style="font-size:15px;font-weight: normal">秒</span>
          </span>
          <br />答题时长
        </div>
      </div>
      <div class="num-box" v-if="item.qtitle.score == 'false'">
        <div>
          <span class="num">{{fineScoreType[item.fineScore]}}</span>
          <br />作业成绩
        </div>
       
        <div>
          <span class="num">
            {{item.answerDuration.split("分钟")[0]}}
            <span style="font-size:15px;font-weight: normal">分</span>
            {{item.answerDuration.split("分钟")[1].split("秒")[0]}}
            <span style="font-size:15px;font-weight: normal">秒</span>
          </span>
          <br />答题时长
        </div>
      </div>
      <div class="paper-box">
        <stucomment :info="item"></stucomment>
      </div>
    </div>
    <!-- <el-pagination style="text-align:right;margin-right:50px;margin-bottom:50px" :page-sizes="[5, 10, 15]" -->
    <div class="pagination">
      <el-pagination :page-sizes="[5, 10, 15]" :page-size="pagesize" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" layout="prev, pager, next, sizes" background :total="allsize">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import stucomment from "./stucomment";

  export default {
    props: {
      workid: Number,
      // scoreType:
    },
    components: {
      stucomment,
    },
    data() {
      return {
        stulist: [],
         fineScoreType:{
          0:'不合格',
          1:'合格',
          2:'暂未批阅',
          3:'良好',
          4:'优秀',
        },
        pagesize: 5,
        currentpage: 1,
        imgurl: this.downloadURL,
        imgUrl: 'this.src="' + require("../../assets/images/home/avatar.png") + '"',
        allsize: null
      };
    },
    methods: {
      handleSizeChange(size) {
        console.log('每页条数', size)
        this.pagesize = size
        this.getotherlist()
      },
      handleCurrentChange(page) {
        console.log('当前页', page)
        this.currentpage = page
        this.getotherlist()
      },
      //获取已提交学员列表
      getotherlist() {
        let params = {
          questionnaireId: this.workid,
          page: this.currentpage,
          limit: this.pagesize,
        };
        this.$Api.Form.getSubInfo(params).then((res) => {
          // console.log(res);
          this.allsize = res.data.totalCount;
          res.data.list.map((item) => {
            if (item.correctingContent) {
              item.correctingContent = JSON.parse(item.correctingContent);
            }
            if (item.qtitle) {
              item.qtitle = JSON.parse(item.qtitle);
            }
          });
          this.stulist = res.data.list;
          this.allsiez = res.data.totalCount
        });
      },
    },
    mounted() {
      this.getotherlist();
    },
  };
</script>

<style lang="less" scoped>
  .otherwrap {
    box-sizing: border-box;

    .stuitem {
      padding: 21px 30px;
      margin: 0 0 42px;
      background-color: rgba(204, 204, 204, 0.1);
      box-sizing: border-box;
      border-radius: 8px;

      .item-info {
        display: flex;
        align-items: center;

        .avatar {
          width: 51px;
          height: 51px;
          border-radius: 50%;
          margin-right: 10px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .userinfo {
          .name {
            span:first-child {
              font-size: 17px;
              font-family: Source Han Sans CN;
              font-weight: 500;
            }

            span:last-child {
              font-size: 20px;
              color: #ff9900;
              font-weight: 500;

              i {
                font-size: 13px;
                color: #ff9900;
              }
            }
          }

          /deep/ .el-rate {
            margin-left: 10px;
          }

          .time {
            color: #666666;
            font-size: 12px;
            margin-top: 3px;
          }
        }
      }

      .num-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(80, 142, 249, .06);
        padding: 18px 75px;
        margin: 21px auto;
        text-align: center;
        box-sizing: border-box;
        font-size: 12px;

        .num {
          color: #5473E8;
          font-weight: bold;
          font-size: 25px;
        }

        & div:nth-child(2) .num {
          color: #36C2CF;
        }

        & div:nth-child(3) .num {
          color: #F6B926;
        }

        & div:nth-child(4) .num {
          color: #EF5757;
        }

        & div:nth-child(5) .num {
          color: #B05BCF;
        }
      }
    }

    @media screen and (min-width:1250px) {
      .stuitem {
        padding: 25px 35px;
        margin: 0 0 29px;

        .item-info {

          .avatar {
            width: 52px;
            height: 52px;
          }

          .userinfo {
            .name {
              span:first-child {
                font-size: 20px;
              }

              span:last-child {
                font-size: 24px;

                i {
                  font-size: 16px;
                }
              }
            }


            .time {
              font-size: 14px;
            }
          }
        }

        .num-box {
          padding: 22px 90px;
          margin: 26px auto;
          font-size: 15px;

          .num {
            font-size: 30px;
          }

        }
      }
    }

    .pagination {
      margin-bottom: 20px;
      text-align: right;
    }

    /deep/.el-pagination {
      .btn-prev,
      .btn-next {
        background-color: transparent;
      }

      .el-pager .number {
        color: #777777;
        height: 30px;
        min-width: 30px;
        padding: 0;
        margin: 0 4px
      }

      .el-pager .active {
        color: #fff;
        background-color: #508EF9;
      }

      .el-pager li.active+li {
        border-left: 1px solid #dfdfdf;
      }

      .el-pager li.btn-quicknext,
      .el-pager li.btn-quickprev {
        background-color: transparent;
      }
    }
  }
</style>