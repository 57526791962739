<template>
  <div class="work-detail">
    <div class="work-detail-wrapper manage-wrapper">
      <div class="explain">
        <h3 class="explain_title">{{ workAttr.workname }}</h3>
        <div class="explain_content">
          <!-- <div class="toggle">
            <span @click="toggleExp">
              {{ showExplain ? "收起说明" : "展开说明" }}
            </span>
            <i :class="showExplain ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
          </div> -->
          <div class="content" v-if="showExplain">
            <div class="explain_item">作业主题：{{ workTheme }}</div>
            <div class="explain_item flex-layout">
              <div class="work_title">
                作业说明：
              </div>
              <div class="work_content" v-html="explain"></div>
            </div>
            <div class="explain_item">
              提交时间：{{ workAttr.beginTime }} --- {{ workAttr.endTime }}
            </div>
            <div class="explain_item">积分：100积分</div>
            <div class="explain_item">能力标准：{{ powers }}</div>
          </div>
        </div>
      </div>

      <div class="work">
        <el-tabs v-model="activeName">
          <el-tab-pane label="我的作业" name="first" class="first">
            <div class="userInfo">
              <div class="user flex-align-between">
                <div class="user_left flex-align">
                  <div class="avatar">
                    <!-- <img :src="userInfo.avatar" /> -->
                    <img :src="imgurl + userInfo.avatar" :onerror="imgUrl" alt />
                  </div>
                  <div class="info">
                    <div class="userscore flex-align">
                      <span>{{ userInfo.name }}</span>
                      <el-rate disabled v-model="powerVal" v-if="powerVal"></el-rate>
                      <span style="color: #ff9900" v-if="commentInfo">{{ powerVal }}.0分</span>
                    </div>
                    <div class="time">提交于{{ userData.createTime }}</div>
                  </div>
                </div>
                <div class="user_btn">
                  <div class="btn" @click="edit">编辑</div>
                </div>
              </div>
              <div v-if="workAttr.score == 'true'&&$route.query.type!=6" class="score flex-align-around">
                <template v-if="$route.query.type==4">
                  <div class="score_item">
                    <div class="score_num">
                      {{subjectiveInfo.length>0?speakinfo.monosyllabic+speakinfo.polysyllabic+speakinfo.paragraph+subjectiveInfo[0].score:speakinfo.monosyllabic+speakinfo.polysyllabic+speakinfo.paragraph }}
                    </div>
                    <div class="score_name">总得分</div>
                  </div>
                </template>
                <template v-else>
                  <div class="score_item">
                    <div class="score_num">{{ userData.totalPoints.toFixed(1) }}</div>
                    <div class="score_name">总得分</div>
                  </div>
                </template>

                <template v-if="$route.query.type==4">
                  <div class="score_item">
                    <div class="score_num">{{speakinfo.monosyllabic}}</div>
                    <div class="score_name">单音节得分</div>
                  </div>
                </template>
                <template v-else>
                  <div class="score_item">
                    <div class="score_num">{{ userData.objectiveScore }}</div>
                    <div class="score_name">客观题得分</div>
                  </div>
                </template>
                <template v-if="$route.query.type==4">
                  <div class="score_item">
                    <div class="score_num">{{speakinfo.polysyllabic}}</div>
                    <div class="score_name">多音节得分</div>
                  </div>
                </template>
                <template v-else>
                  <div class="score_item">
                    <div class="score_num">
                      {{ userData.objectiveAccuracy }}<span>%</span>
                    </div>
                    <div class="score_name">客观题正确率</div>
                  </div>
                </template>
                <template v-if="$route.query.type==4">
                  <div class="score_item">
                    <div class="score_num">{{speakinfo.paragraph}}</div>
                    <div class="score_name">短文朗读得分</div>
                  </div>
                </template>
                <template v-else>
                  <div class="score_item">
                    <div class="score_num">{{ userData.subjectiveScore.toFixed(1) }}</div>
                    <div class="score_name">主观题得分</div>
                  </div>
                </template>
                <template v-if="$route.query.type==4">
                  <div class="score_item">
                    <div class="score_num">{{subjectiveInfo.length>0?subjectiveInfo[0].score:0}}</div>
                    <div class="score_name">命题说话得分</div>
                  </div>
                </template>
                <div class="score_item">
                  <template v-if="$route.query.type==4">
                    <div class="score_num">
                      {{speakminutes}}
                      <span>分钟</span>
                      {{speakseconds}}
                      <span>秒</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="score_num">
                      <!-- {{userData.answerDuration.match(/\d+/g).length <= 2}} -->
                      {{userData.answerDuration && userData.answerDuration.match(/\d+/g)[0]}}
                      <span>分钟</span>
                      {{userData.answerDuration && userData.answerDuration.match(/\d+/g)[1]}}
                      <span>秒</span>
                    </div>
                  </template>
                  <div class="score_name">答题时长</div>
                </div>
              </div>
              <div v-if="workAttr.score== 'false'" class="score flex-align-around">
                <template>
                  <div class="score_item">
                    <div class="score_num">{{fineScoreType[userData.fineScore]}}</div>
                    <div class="score_name">作业成绩</div>
                  </div>
                </template>
                <div class="score_item">
                  <template>
                    <div class="score_num">
                      <!-- {{userData.answerDuration.match(/\d+/g).length <= 2}} -->
                      {{usersData.answerDuration && usersData.answerDuration.match(/\d+/g)[0]}}
                      <span>分钟</span>
                      {{usersData.answerDuration && usersData.answerDuration.match(/\d+/g)[1]}}
                      <span>秒</span>
                    </div>
                  </template>
                  <div class="score_name">答题时长</div>
                </div>
              </div>
              <div v-if="workAttr.score== 'false'">
                <el-tabs v-model="active" @tab-click="handleClick">
                  <el-tab-pane v-if="commentInfo" label="查看评论" name="first">
                    <div class="toggle">
                      <span @click="toggleCom">
                        {{ showComment ? "收起评论" : "展开评论" }}
                      </span>
                      <i :class="showComment ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
                    </div>
                    <div class="teaComment" v-if="showComment && commentInfo">
                      <div class="teaInfo">
                        <div class="info">
                          <div class="tea_avatar">
                            <img :src="imgurl + userData.correctingUserCover" />
                          </div>
                          <div class="tea_name">{{ userData.correctingUserName }}</div>
                          <div class="tea_time">
                            点评于 {{ userData.correctingTime }}
                          </div>
                        </div>
                      </div>
                      <div class="content">
                        {{ commentInfo }}
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane v-if="FineComentArr.length>0" label="查看评语" name="second">
                    <el-carousel indicator-position="none" :autoplay="false" height="130px">
                      <el-carousel-item v-for="(item,index) in FineComentArr" :key="index">
                        <div class="user-carousel">
                          <div class="item-left flex-align">
                            <!-- <img v-if="item.correctingUserUrl" :src="item.correctingUserUrl" alt /> -->
                            <img src="@/assets/images/home/avatar.png" alt />
                            <div class="name">{{item.correctingUserName}}</div>
                            <div class="time">{{item.updateCorrectingTime}}</div>
                            <!-- <div class="bianji" v-if="item.correctingUserId == userinfoID">编辑</div> -->
                          </div>
                          <div class="item-right">{{item.correctingFineComment}}</div>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <div v-else>
                <div class="toggle" v-if="commentInfo">
                  <span @click="toggleCom">
                    {{ showComment ? "收起评论" : "展开评论" }}
                  </span>
                  <i :class="showComment ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
                </div>
                <div class="teaComment" v-if="showComment && commentInfo">
                  <div class="teaInfo">
                    <div class="info">
                      <div class="tea_avatar">
                        <img :src="imgurl + userData.correctingUserCover" />
                      </div>
                      <div class="tea_name">{{ userData.correctingUserName }}</div>
                      <div class="tea_time">
                        点评于 {{ userData.correctingTime }}
                      </div>
                    </div>
                  </div>
                  <div class="content">
                    {{ commentInfo }}
                  </div>
                </div>
                <el-tabs v-model="active" @tab-click="handleClick">
                  <el-tab-pane v-if="FineComentArr.length>0" label="查看评语" name="second">
                    <el-carousel indicator-position="none" :autoplay="false" height="130px">
                      <el-carousel-item v-for="(item,index) in FineComentArr" :key="index">
                        <div class="user-carousel">
                          <div class="item-left flex-align">
                            <!-- <img v-if="item.correctingUserUrl" :src="item.correctingUserUrl" alt /> -->
                            <img src="@/assets/images/home/avatar.png" alt />
                            <div class="name">{{item.correctingUserName}}</div>
                            <div class="time">{{item.updateCorrectingTime}}</div>
                            <!-- <div class="bianji" v-if="item.correctingUserId == userinfoID">编辑</div> -->
                          </div>
                          <div class="item-right">{{item.correctingFineComment}}</div>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
            <div class="workname"><span>答卷详情</span></div>
            <div class="container">
              <!-- 中部生成的表单 -->
              <div class="center-board">
                <!-- 生成的表单 -->
                <el-scrollbar class="center-scrollbar">
                  <el-row class="center-board-row" :gutter="formConf.gutter">
                    <el-form :size="formConf.size" :label-position="formConf.labelPosition"
                      :disabled="formConf.disabled" :label-width="formConf.labelWidth + 'px'">
                      <draggable class="drawing-board" :list="drawingList" :animation="340" group="componentsGroup"
                        :options="dragOptions">
                        <template v-if="workAttr.score== 'false'">
                          <draggable-item v-for="(item, index) in drawingList" :key="item.renderKey"
                            :drawing-list="drawingList" :current-item="item" :index="index" :active-id="activeId"
                            :form-conf="formConf" @activeItem="activeFormItem" @copyItem="drawingItemCopy"
                            @deleteItem="drawingItemDelete" />
                        </template>
                        <template v-else>
                          <draggable-item2 v-for="(item, index) in drawingList" :key="item.renderKey"
                            :drawing-list="drawingList" :current-item="item" :index="index" :active-id="activeId"
                            :form-conf="formConf" @activeItem="activeFormItem" @copyItem="drawingItemCopy"
                            @deleteItem="drawingItemDelete" />
                        </template>
                        <div class="fujian" v-for="(item, index) in fujianList" :key="index">
                          <div class="fujian_title flex-align">
                            {{ item.title }} 
                            <el-button v-if="item.remark" type="text" @click="openReport(item.remark)">分析报告</el-button>
                          </div>
                          <!-- 上传的文件说明 -->
                          <div class="fujian_explain" v-if="item.explain">
                            {{item.explain}}
                          </div>
                          <!-- 文件 -->
                          <div class="file" v-for="(items, index) in item.filelist" :key="'q' + index">
                            <i class="el-icon-tickets"></i>
                            <a :href="imgurl + items.id" target="_blank" style="margin-left: 10px">{{ items.name }}</a>
                          </div>
                          <!-- 图片 -->
                          <el-image v-for="(items, index) in item.imglist" :key="'w' + index" style="
                            width: 200px;
                            height: 150px;
                            margin-right: 20px;
                            border-radius: 5px;
                          " :src="imgurl + items.id" :preview-src-list="item.srclist">
                          </el-image>
                          <!-- 视频 -->
                          <div v-for="(items, index) in item.videolist" :key="'e' + index">
                            <VideoPlay :file="items.id+index" :id="items.id"></VideoPlay>
                          </div>
                          <!-- <video v-for="(items, index) in item.videolist" :key="'e' + index" :src="imgurl + items.id"
                            controls="controls" width="200px" height="150"
                            style="display: block; margin: 10px 20px 10px 20px">
                            您的浏览器不支持播放该视频！
                          </video> -->
                          <!-- 音频 -->
                          <audio style="display: block; margin-top: 10px" v-for="(items, index) in item.audiolist"
                            :key="'r' + index" controls>
                            <source :src="imgurl + items.id" type="audio/mpeg" />
                            如果该元素不被浏览器支持，则本段文本被显示。
                          </audio>
                        </div>
                      </draggable>

                      <ul>
                        <li style="display: inline-block" v-for="(item, index) in kejianlist" :key="'t' + index">
                          <img style="width: 40px; height: 40px" :src="item" />
                        </li>
                      </ul>
                    </el-form>
                  </el-row>
                </el-scrollbar>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="其他学员作业" name="second" v-if="!workAttr.isLook">
            <otherwork :workid="parseInt(this.$route.query.questionId)" v-if="activeName == 'second'"></otherwork>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import {
    debounce
  } from "throttle-debounce";
  import {
    saveAs
  } from "file-saver";
  import ClipboardJS from "clipboard";
  import render from "@/components/render/render";
  import JsonDrawer from "./JsonDrawer";
  import RightPanel from "./RightPanel";
  import {
    inputComponents,
    selectComponents,
    // layoutComponents,
    formConf,
  } from "@/components/generator/config_view";
  import {
    exportDefault,
    beautifierConf,
    isNumberStr,
    titleCase,
    deepClone,
  } from "@/utils/index";
  import {
    makeUpHtml,
    vueTemplate,
    vueScript,
    cssStyle,
  } from "@/components/generator/html";
  import {
    makeUpJs
  } from "@/components/generator/js";
  import {
    makeUpCss
  } from "@/components/generator/css";
  import drawingDefalut from "@/components/generator/drawingDefalut";
  import logo from "@/assets/logo.png";
  import CodeTypeDialog from "./CodeTypeDialog";
  import DraggableItem from "./DraggableItem4";
  import DraggableItem2 from "./DraggableItem2";
  import {
    getDrawingList,
    saveDrawingList,
    getIdGlobal,
    saveIdGlobal,
    getFormConf,
  } from "@/utils/db";
  import loadBeautifier from "@/utils/loadBeautifier";

  import otherwork from "./otherwork";

  let beautifier;
  const emptyActiveData = {
    style: {},
    autosize: {},
  };
  let oldActiveId;
  let tempActiveData;
  const drawingListInDB = getDrawingList();
  const formConfInDB = getFormConf();
  const idGlobal = getIdGlobal();

  export default {
    components: {
      draggable,
      render,
      JsonDrawer,
      RightPanel,
      CodeTypeDialog,
      DraggableItem,
      DraggableItem2,
      otherwork,
    },
    data() {
      return {
        fineScoreType: {
          0: '不合格',
          1: '合格',
          2: '暂未批阅',
          3: '良好',
          4: '优秀',
        },
        FineComentArr: [],
        UploadPicture: false, //上传图片
        active: 'second',
        Uploadname: "",
        kejianlist: [],
        dragOptions: {
          sort: false, //定义生成的表单是否可以拖拽
        },
        logo,
        idGlobal,
        formConf,
        inputComponents,
        selectComponents,
        // layoutComponents,
        labelWidth: 100,
        drawingList: drawingDefalut,
        drawingData: {},
        activeId: drawingDefalut[0].formId,
        drawerVisible: false,
        formData: {},
        dialogVisible: false,
        jsonDrawerVisible: false,
        generateConf: null,
        showFileName: false,
        activeData: drawingDefalut[0],
        saveDrawingListDebounce: debounce(340, saveDrawingList),
        saveIdGlobalDebounce: debounce(340, saveIdGlobal),
        explain: "", //自定义的作业说明
        showExplain: true, //默认展开说明
        showComment: true, //默认展开评论
        activeName: "first",
        powerVal: null, //评分（针对于每套问卷）
        workInfoAll: "", //作业全部信息
        workAttr: {}, //作业属性
        workTheme: "", //作业主题
        userData: "", //用户答题数据
        usersData: "", //用户答题数据
        userInfo: "", //学员信息
        commentInfo: "", //评论信息
        subjectiveInfo: [], //主观题批改信息
        powers: "", //二级能力标准
        imgurl: this.downloadURL,
        imgUrl: 'this.src="' + require("../../assets/images/home/avatar.png") + '"',
        fujianList: [], //附件题数组
        videoTypes: [
          "mp4",
          "3gp",
          "m4v",
          "mkv",
        ],
        audioTypes: ["wav", "mp3", "aac", ],
        picTypes: ["bmp", "gif", "png", "jpg", "jpeg"],
        fileTypes: ["doc", "docx", "ppt", "pptx", "xls", "xlsx", "pdf", "txt", "wps", "zip", "rar"],
        deleid: "", //删除答题记录的id 仅供测试使用
        prevInfo: {
          prevPath: '/',
          prevPage: '课程详情',
          ancestryPath: '/',
          ancestryPage: '我的课程',
        }, //上个页面名称
        speakinfo: '', //普通话得分信息
        speakminutes: null,
        speakseconds: null
      };
    },
    computed: {
      prevPage() {
        if (this.$route.query.from == 'course') {
          return this.prevInfo = {
            prevPath: `/course/${this.$route.query.courseId}`,
            prevPage: '课程详情',
            ancestryPath: 'course',
            ancestryPage: '我的课程',
            prevQuery: {
              index: this.$route.query.index
            }
          }
        } else {
          return this.prevInfo = {
            prevPath: `/mytask/detail/${this.workInfoAll.themeId}`,
            prevPage: this.workInfoAll.themeName,
            ancestryPath: 'mytask',
            ancestryPage: '我的任务',
          }
        }
      }
    },
    watch: {
      // eslint-disable-next-line func-names
      "activeData.__config__.label": function (val, oldVal) {
        if (
          this.activeData.placeholder === undefined ||
          !this.activeData.__config__.tag ||
          oldActiveId !== this.activeId
        ) {
          return;
        }
        this.activeData.placeholder =
          this.activeData.placeholder.replace(oldVal, "") + val;
      },
      activeId: {
        handler(val) {
          oldActiveId = val;
        },
        immediate: true,
      },
      drawingList: {
        handler(val) {
          this.saveDrawingListDebounce(val);
          if (val.length === 0) this.idGlobal = 100;
        },
        deep: true,
      },
      idGlobal: {
        handler(val) {
          this.saveIdGlobalDebounce(val);
        },
        immediate: true,
      },
    },
    mounted() {
      if (Array.isArray(drawingListInDB) && drawingListInDB.length > 0) {
        this.drawingList = drawingListInDB;
      } else {
        this.drawingList = drawingDefalut;
      }
      this.activeFormItem(this.drawingList[0]);
      if (formConfInDB) {
        this.formConf = formConfInDB;
      }
      loadBeautifier((btf) => {
        beautifier = btf;
      });
      const clipboard = new ClipboardJS("#copyNode", {
        text: (trigger) => {
          const codeStr = this.generateCode();
          this.$notify({
            title: "成功",
            message: "代码已复制到剪切板，可粘贴。",
            type: "success",
          });
          return codeStr;
        },
      });
      clipboard.on("error", (e) => {
        this.$message.error("代码复制失败");
      });
    },
    activated() {
      // console.log(this.fujianList)
      this.fujianList = [];
      localStorage.removeItem("drawingItems");
      this.getForm();
      this.getFineScore()
      this.getFineComent()
      // this.userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
      this.userInfo = this.$store.state.user.userInfo;
      this.getpowers();
    },
    methods: {
      handleClick(tab, event) {},
      //查看附件题
      checkFile(url) {
        window.open(this.downloadURL + url);
        // console.log(this.downloadURL + url);
      },
      //获取问卷顶部能力标准
      getpowers() {
        this.$Api.Form.getSecondPower(this.$route.query.questionId)
          .then((value) => {
            // console.log("获取顶部能力标准", value);
            if (value.data.length > 0) {
              let str = "";
              value.data.map((item) => {
                return (str += item.title + ",");
              });
              this.powers = str.slice(0, str.length - 1);
            }
          })
          .catch((reason) => {
            console.log(reason);
          });
      },
      //展开收起作业说明
      toggleExp() {
        this.showExplain = !this.showExplain;
      },
      //展开收起评论
      toggleCom() {
        this.showComment = !this.showComment;
      },
      //获取普通话得分
      getSpeakScore(mdQuestionnaireRelId) {
        let param = {
          relId: mdQuestionnaireRelId,
          // userId: JSON.parse(sessionStorage.getItem("userinfo")).id,
          userId: this.$store.state.user.userInfo.id,
        }
        this.$Api.Form.getreport(param)
          .then(res => {
            console.log("获取普通话得分--", res)
            let minutes = Math.floor(res.data.consumeTime / 60);
            let seconds = Math.floor(res.data.consumeTime % 60);
            if (seconds < 10) {
              seconds = "0" + seconds
            }
            if (minutes < 10) {
              minutes = "0" + minutes
            }
            this.speakminutes = minutes
            this.speakseconds = seconds
            this.speakinfo = res.data
          })
          .catch(err => {
            console.log("获取普通话得分失败", err)
          })
      },
      // 获取成绩
      getFineScore() {
        this.$Api.Form.getFineScore(
            // JSON.parse(sessionStorage.getItem("userinfo")).id,
            this.$store.state.user.userInfo.id,
            this.$route.query.questionId
          )
          .then((res) => {
            console.log(res)
            this.usersData = res.data;
          }).catch((err) => {
            console.log(err);
          });
      },
      // 获取成绩
      getFineComent() {
        this.$Api.Form.getFineComent(
            // JSON.parse(sessionStorage.getItem("userinfo")).id,
            this.$store.state.user.userInfo.id,
            this.$route.query.questionId
          )
          .then((res) => {
            this.FineComentArr = res.data;
          }).catch((err) => {
            console.log(err);
          });
      },
      //获取学员答题问卷及答案
      getForm() {
        this.$Api.Form.checkForm(
            // JSON.parse(sessionStorage.getItem("userinfo")).id,
            this.$store.state.user.userInfo.id,
            this.$route.query.questionId
          )
          .then((res) => {
            console.log(res);
            this.deleid = res.data.id;
            this.workAttr = JSON.parse(res.data.qtitle);
            console.log(this.workAttr)
            this.userData = res.data;

            if (res.data.correctingContent) {
              this.commentInfo = JSON.parse(res.data.correctingContent).comment;
              this.powerVal = JSON.parse(res.data.correctingContent).score;
              this.subjectiveInfo = JSON.parse(
                res.data.correctingContent
              ).checkList;
            } else {
              this.commentInfo = "";
            }
            let content = JSON.parse(res.data.qcontent);
            // console.log("问卷列表---", content);
            content.map((item) => {
              //将作业说明取出
              if (item.__config__.tag == "tinymce") {
                this.explain = item.__config__.defaultValue;
              }
              //根据表单类型修改对应的默认值类型 不然会报错
              if (item.__config__.tag == "el-checkbox-group") {
                item.__config__.defaultValue = [];
              } else {
                item.__config__.defaultValue = "";
              }
              //将表单改为只读或者禁用状态
              if (item.__config__.tag == "el-input") {
                item.readonly = true;
              } else {
                item.disabled = true;
              }
            });

            //将作业说明与附件题过滤出去只显示问卷内容
            content = content.filter((item) => {
              return item.__config__.tag != "tinymce";
            });
            // console.log("问卷列表", content);
            //获取学员回答的答案
            let resultlist = JSON.parse(res.data.content);
            console.log("学员答案", resultlist);

            //将获取的答案赋值给对应的空问卷
            for (let i = 0; i < content.length; i++) {
              for (let j = 0; j < resultlist.length; j++) {
                if (content[i].__vModel__ == resultlist[j].id) {
                  content[i].__config__.defaultValue = resultlist[j].result
                  content[i].fjexplain = resultlist[j].explain
                  content[i].remark = resultlist[j].remark?resultlist[j].remark:''
                  resultlist.splice(j, 1)
                  break;
                }
              }
            }
            console.log("学生的答题情况--", content);
            content.map((item) => {
              //判断是否是附件题
              if (item.__config__.tag == "el-upload") {
                let obj = {
                  srclist: [],
                };
                obj.explain = item.fjexplain;
                obj.title = item.__config__.label;
                obj.remark = item.remark;
                if (item.__config__.defaultValue) {
                  (obj.imglist = item.__config__.defaultValue.filter((item) => {
                    return this.picTypes.indexOf(item.type.toLowerCase()) != -1;
                  })),
                  (obj.videolist = item.__config__.defaultValue.filter(
                    (item) => {
                      return (
                        this.videoTypes.indexOf(item.type.toLowerCase()) != -1
                      );
                    }
                  )),
                  (obj.audiolist = item.__config__.defaultValue.filter(
                    (item) => {
                      return (
                        this.audioTypes.indexOf(item.type.toLowerCase()) != -1
                      );
                    }
                  )),
                  (obj.filelist = item.__config__.defaultValue.filter(
                    (item) => {
                      return (
                        this.videoTypes.indexOf(item.type.toLowerCase()) == -1 && this.picTypes.indexOf(item
                          .type.toLowerCase()) == -1 && this.audioTypes.indexOf(item.type.toLowerCase()) == -1
                      );
                    }
                  ));
                  item.__config__.defaultValue.map((item) => {
                    if (this.picTypes.indexOf(item.id.split("_")[2]) != -1) {
                      obj.srclist.push(this.imgurl + item.id);
                    }
                  });
                  this.fujianList.push(obj);
                } else {
                  (obj.imglist = []),
                  (obj.videolist = []),
                  (obj.audiolist = []),
                  (obj.filelist = []),
                  (obj.srclist = []);
                  this.fujianList.push(obj);
                }
              }
            });
            // console.log("附件数组--", this.fujianList);
            content = content.filter((item) => {
              return item.__config__.tag != "el-upload";
            });

            // 获取问卷正确答案
            this.$Api.Form.getTeaForm(this.$route.query.questionId)
              .then((value) => {
                this.workInfoAll = value.data;
                console.log("获取问卷正确答案", value);
                this.workTheme = value.data.themeName;
                let rightList = JSON.parse(value.data.answer);

                //获取普通话得分信息
                if (value.data.mdQuestionnaireRelId) {
                  this.getSpeakScore(value.data.mdQuestionnaireRelId)
                }
                // console.log("正确答案", rightList);
                // console.log("content", content);
                // console.log("-----", content);
                content.map((item) => {
                  if (rightList.hasOwnProperty(item.__vModel__) == true) {
                    item.__config__.rightVal = rightList[item.__vModel__];
                    // if (item.__config__.defaultValue.sort().toString() == rightList[item.__vModel__].sort().toString()) {
                    //   item.__config__.userScore = item.__config__.scoreValue;
                    // } else {
                    //   item.__config__.userScore = 0;
                    // }
                    if (item.__config__.tag == "el-radio-group") {
                      if (
                        item.__config__.defaultValue.toString() ==
                        item.__config__.rightVal.toString()
                      ) {
                        item.__config__.userScore = item.__config__.scoreValue;
                      } else {
                        item.__config__.userScore = 0;
                      }
                    }
                    if (item.__config__.tag == "el-checkbox-group") {
                      if (
                        item.__config__.defaultValue.sort().toString() ==
                        item.__config__.rightVal.sort().toString()
                      ) {
                        item.__config__.userScore = item.__config__.scoreValue;
                      } else {
                        item.__config__.userScore = 0;
                      }
                    }
                  }
                  item.__config__.defaultValue = item.__config__.defaultValue;
                });
                //给主观题添加得分
                for (var i = 0; i < this.subjectiveInfo.length; i++) {
                  for (var j = 0; j < content.length; j++) {
                    if (this.subjectiveInfo[i].formid == content[j].__vModel__) {
                      content[j].__config__.userScore = this.subjectiveInfo[
                        i
                      ].score;
                    }
                  }
                }
                // console.log("主观题批改信息--", this.subjectiveInfo);
                // console.log("处理后的问卷--", content);

                //将处理后的表单渲染到页面
                localStorage.setItem("drawingItems", JSON.stringify(content));
                this.drawingList = JSON.parse(
                  localStorage.getItem("drawingItems")
                );
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      //编辑(二次答题)
      edit() {
        if (!this.workAttr.edit) {
          this.$alert("当前问卷不支持多次编辑", "提示信息", {
            confirmButtonText: "确定",
          });
        } else {
          let breadListLength = this.$store.state.basics.breadList.length
          this.$router.push({
            path: "/answerAgain",
            query: {
              questionId: this.$route.query.questionId,
              // userId: JSON.parse(sessionStorage.getItem("userinfo")).id,
              userId: this.$store.state.user.userInfo.id,
              answerAgain: true,
              themeName: this.workTheme,
              from: this.$route.query.from,
              courseId: this.$route.query.courseId,
              breadNumber: breadListLength + 1
            },
          });
        }
      },
      //查看作业报告
      report() {
        this.$router.push("/workReport");
      },
      activeFormItem(currentItem) {
        this.activeData = currentItem;
        this.activeId = currentItem.__config__.formId;
      },
      onEnd(obj) {
        if (obj.from !== obj.to) {
          this.activeData = tempActiveData;
          this.activeId = this.idGlobal;
        }
      },
      addComponent(item) {
        const clone = this.cloneComponent(item);
        this.drawingList.push(clone);
        this.activeFormItem(clone);
      },
      cloneComponent(origin) {
        const clone = deepClone(origin);
        const config = clone.__config__;
        config.span = this.formConf.span; // 生成代码时，会根据span做精简判断
        this.createIdAndKey(clone);
        clone.placeholder !== undefined && (clone.placeholder += config.label);
        tempActiveData = clone;
        return tempActiveData;
      },
      createIdAndKey(item) {
        const config = item.__config__;
        config.formId = ++this.idGlobal;
        config.renderKey = `${config.formId}${+new Date()}`; // 改变renderKey后可以实现强制更新组件
        if (config.layout === "colFormItem") {
          item.__vModel__ = `field${this.idGlobal}`;
        } else if (config.layout === "rowFormItem") {
          config.componentName = `row${this.idGlobal}`;
          !Array.isArray(config.children) && (config.children = []);
          delete config.label; // rowFormItem无需配置label属性
        }
        if (Array.isArray(config.children)) {
          config.children = config.children.map((childItem) =>
            this.createIdAndKey(childItem)
          );
        }
        return item;
      },
      AssembleFormData() {
        this.formData = {
          fields: deepClone(this.drawingList),
          ...this.formConf,
        };
      },
      generate(data) {
        const func = this[`exec${titleCase(this.operationType)}`];
        this.generateConf = data;
        func && func(data);
      },
      execRun(data) {
        this.AssembleFormData();
        this.drawerVisible = true;
      },
      execDownload(data) {
        const codeStr = this.generateCode();
        const blob = new Blob([codeStr], {
          type: "text/plain;charset=utf-8",
        });
        saveAs(blob, data.fileName);
      },
      execCopy(data) {
        document.getElementById("copyNode").click();
      },
      drawingItemCopy(item, list) {
        let clone = deepClone(item);
        clone = this.createIdAndKey(clone);
        list.push(clone);
        this.activeFormItem(clone);
      },
      drawingItemDelete(index, list) {
        list.splice(index, 1);
        this.$nextTick(() => {
          const len = this.drawingList.length;
          if (len) {
            this.activeFormItem(this.drawingList[len - 1]);
          }
        });
      },
      generateCode() {
        const {
          type
        } = this.generateConf;
        this.AssembleFormData();
        const script = vueScript(makeUpJs(this.formData, type));
        const html = vueTemplate(makeUpHtml(this.formData, type));
        const css = cssStyle(makeUpCss(this.formData));
        return beautifier.html(html + script + css, beautifierConf.html);
      },
      download() {
        this.dialogVisible = true;
        this.showFileName = true;
        this.operationType = "download";
      },
      run() {
        this.dialogVisible = true;
        this.showFileName = false;
        this.operationType = "run";
      },
      copy() {
        this.dialogVisible = true;
        this.showFileName = false;
        this.operationType = "copy";
      },
      tagChange(newTag) {
        newTag = this.cloneComponent(newTag);
        const config = newTag.__config__;
        newTag.__vModel__ = this.activeData.__vModel__;
        config.formId = this.activeId;
        config.span = this.activeData.__config__.span;
        this.activeData.__config__.tag = config.tag;
        this.activeData.__config__.tagIcon = config.tagIcon;
        this.activeData.__config__.document = config.document;
        if (
          typeof this.activeData.__config__.defaultValue ===
          typeof config.defaultValue
        ) {
          config.defaultValue = this.activeData.__config__.defaultValue;
        }
        Object.keys(newTag).forEach((key) => {
          if (this.activeData[key] !== undefined) {
            newTag[key] = this.activeData[key];
          }
        });
        this.activeData = newTag;
        this.updateDrawingList(newTag, this.drawingList);
      },
      updateDrawingList(newTag, list) {
        const index = list.findIndex(
          (item) => item.__config__.formId === this.activeId
        );
        if (index > -1) {
          list.splice(index, 1, newTag);
        } else {
          list.forEach((item) => {
            if (Array.isArray(item.__config__.children))
              this.updateDrawingList(newTag, item.__config__.children);
          });
        }
      },
      refreshJson(data) {
        this.drawingList = deepClone(data.fields);
        delete data.fields;
        this.formConf = data;
      },
      openReport(remark){
        if(remark.id){
          window.open(this.imgurl + remark.id)
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/styles/home";

  .work-detail {
    background: #f9f9f9;

    .explain {
      padding: 0 46px;
      margin: 0 auto 19px;
      background-color: #ffffff;
      border-radius: 8px;

      .explain_title {
        line-height: 49px;
        font-size: 13px;
        border-bottom: 1px solid #F5F4F4;

        &::before {
          content: ' ';
          display: inline-block;
          margin-right: 11px;
          width: 4px;
          height: 21px;
          background-color: #508EF9;
          border-radius: 2px;
          vertical-align: -5px;
        }
      }

      .explain_content {
        box-sizing: border-box;
        padding: 15px 31px 20px 14px;
        color: #999;
        font-size: 12px;

        .toggle {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .explain_item {
          margin-bottom: 10px;
        }

        .work_title {
          flex-shrink: 0;
        }

        .work_title,
        .work_content {
          display: inline-block;

        }
      }
    }

    .work {
      padding: 0 46px;
      margin: 0 auto;
      background-color: #ffffff;
      // box-shadow: 1px 5px 29px 0px rgba(22, 116, 255, 0.05);
      border-radius: 8px;

      ::v-deep .el-tabs__nav {

        .el-tabs__item {
          font-weight: 400;
          color: #333;
          font-size: 14px;
        }

        .is-active {
          color: #508EF9;
        }

        .el-tabs__active-bar {
          background-color: #508EF9;
          height: 1px;
        }
      }

      .first {
        .userInfo {
          padding: 0 13px 0 16px;
          margin: 16px auto 28px;

          .el-rate {
            margin-left: 10px;
          }

          .user {

            .user_left {

              .avatar {
                width: 51px;
                height: 51px;
                border-radius: 50%;
                margin-right: 9px;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .info {
                span {
                  font-size: 17px;
                  // margin-right: 10px;
                }

                .time {
                  margin-top: 6px;
                  font-size: 12px;
                  color: #666666;
                }
              }
            }

            .user_btn {
              display: flex;

              .btn {
                cursor: pointer;
                line-height: 31px;
                background-color: #508EF9;
                border-radius: 4px;
                padding: 0 43px;
                color: #ffffff;
                font-size: 12px;
              }
            }
          }

          .score {
            height: 100px;
            background: #FBFAFA;
            margin: 26px 0px 0px;

            .score_item {
              display: flex;
              align-items: center;
              flex-direction: column;

              .score_num {
                color: #5473E8;
                font-size: 25px;
                font-weight: bold;

                span {
                  font-size: 14px;
                }
              }

              &:nth-child(2) .score_num {
                color: #36C2CF;
              }

              &:nth-child(3) .score_num {
                color: #F6B926;
              }

              &:nth-child(4) .score_num {
                color: #EF5757;
              }

              &:nth-child(5) .score_num {
                color: #B05BCF;
              }

              .score_name {
                color: #333333;
                font-size: 12px;
              }
            }
          }

          .fun {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0 40px;

            span {
              display: flex;
              align-items: center;
              margin-left: 20px;

              img {
                margin-right: 10px;
              }
            }

            .comment_wrap {
              width: 700px;
              height: 450px;
              background: #ecf0f7;
            }
          }

          .toggle {
            margin: 10px 0 0;
            border-bottom: 1px solid #f2f2f2;
            padding-bottom: 14px;
          }

          .teaComment {
            padding: 14px 0 10px;

            // background: #ff9900;
            .teaInfo {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .info {
                display: flex;
                align-items: center;

                .tea_avatar {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  background: #edf1f8;
                  overflow: hidden;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .tea_name {
                  font-size: 12px;
                  font-weight: 500;
                  color: #666666;
                  margin: 0 19px;
                }

                .tea_time {
                  font-size: 12px;
                  color: #666666;
                }
              }

              .menu {
                font-size: 14px;
                color: #666666;

                .removeComment {
                  margin-left: 36px;
                }
              }
            }

            .content {
              margin-left: 50px;
              margin-top: 13px;
            }
          }
        }

        .workname {
          height: 60px;
          font-size: 16px;
          line-height: 60px;
          color: #343434;
          font-weight: 500;
          border-bottom: 1px solid #f2f2f2;

          span {
            margin-left: 22px;
          }
        }

        .container {
          display: flex;

          .center-board {
            width: 100%;
            margin: 30px auto;
            border: 1px solid #f2f2f2;
            border-radius: 5px;

            .fujian {
              margin: 0 20px;
              .fujian_title{
                width:100%;
                justify-content: space-between;
              }
              .fujian_explain {
                margin: 20px 0;
                word-break: break-all;
                white-space: pre-line;
              }

              .file {
                padding-left: 10px;
                margin: 10px 0px;
                height: 50px;
                display: flex;
                align-items: center;
                background: #ebeef5;
                width: 50%;
                border-radius: 3px;
              }
            }

            .center-scrollbar {
              border-left: 0;
              border-right: 0;
            }
          }
        }
      }
    }

    @media screen and (min-width:1250px) {
      .explain {
        padding: 0 57px;
        margin: 0 auto 24px;

        .explain_title {
          line-height: 60px;
          font-size: 16px;

          &::before {
            margin-right: 13px;
            width: 5px;
            height: 25px;
          }
        }

        .explain_content {
          padding: 20px 30px 15px 18px;
          font-size: 13px;

          .explain_item {
            margin-bottom: 15px;
          }
        }
      }

      .work {
        padding: 0 57px;

        ::v-deep .el-tabs__nav {

          .el-tabs__item {
            font-size: 16px;
          }
        }

        .first {
          .userInfo {
            padding: 0 16px 0 19px;
            margin: 20px auto 15px;

            .el-rate {
              margin-left: 10px;
            }

            .user {

              .user_left {

                .avatar {
                  width: 62px;
                  height: 62px;
                  margin-right: 11px;
                }

                .info {
                  span {
                    font-size: 20px;
                    // margin-right: 10px;
                  }

                  .time {
                    font-size: 14px;
                  }
                }
              }

              .user_btn {

                .btn {
                  line-height: 38px;
                  padding: 0 52px;
                  font-size: 15px;
                }
              }
            }

            .score {
              height: 100px;
              background: #FBFAFA;
              margin: 26px 0px 0px;

              .score_item {
                display: flex;
                align-items: center;
                flex-direction: column;

                .score_num {
                  font-size: 30px;

                  span {
                    font-size: 16px;
                  }
                }

                .score_name {
                  font-size: 15px;
                }
              }
            }

            .fun {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin: 0 40px;

              span {
                display: flex;
                align-items: center;
                margin-left: 20px;

                img {
                  margin-right: 10px;
                }
              }

              .comment_wrap {
                width: 700px;
                height: 450px;
                background: #ecf0f7;
              }
            }

            .toggle {
              margin: 10px 0 0;
              border-bottom: 1px solid #f2f2f2;
              padding-bottom: 14px;
            }

            .teaComment {
              padding: 17px 0 13px;

              // background: #ff9900;
              .teaInfo {
                .info {

                  .tea_avatar {
                    width: 36px;
                    height: 36px;
                  }

                  .tea_name {
                    font-size: 14px;
                    margin: 0 22px;
                  }

                  .tea_time {
                    font-size: 14px;
                  }
                }

                .menu {
                  font-size: 14px;
                  color: #666666;

                  .removeComment {
                    margin-left: 36px;
                  }
                }
              }

              .content {
                margin-left: 59px;
              }
            }
          }

          .workname {
            // height: 60px;
            font-size: 16px;
            line-height: 60px;

            span {
              margin-left: 22px;
            }
          }

          .container {
            display: flex;

            .center-board {
              width: 100%;
              margin: 30px auto;
              border: 1px solid #f2f2f2;
              border-radius: 5px;

              .fujian {
                margin: 0 20px;

                .file {
                  padding-left: 10px;
                  margin: 10px 0px;
                  height: 50px;
                  display: flex;
                  align-items: center;
                  background: #ebeef5;
                  width: 50%;
                  border-radius: 3px;
                }
              }

              .center-scrollbar {
                border-left: 0;
                border-right: 0;
              }
            }
          }
        }
      }
    }
  }

  .user-carousel {
    padding: 10px 60px;

    .item-left {
      img {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background: #edf1f8;
        overflow: hidden;
      }

      .name {
        font-size: 14px;
        margin-left: 10px;
      }

      .time {
        font-size: 14px;
        margin-left: 10px;
      }

      .bianji {
        margin-left: 10px;
        color: #508EF9;
        font-size: 14px;
        cursor: pointer;
      }

    }

    .item-right {
      margin: 10px 10px;
      font-size: 14px;
      word-wrap: break-word;
      // white-space: normal;
      // word-break: break-all;
      height: 70px;
      max-height: 70px;
      overflow: auto;
      border-radius: 10px;
    }
  }

  .center-board {
    margin: 0 100px;
  }
</style>